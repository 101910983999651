<template>
  <div class="secure-report container template-1">
    <header class="page-header container mx-lg">
      <h1>{{ translations.components['report-parameters'].tcMonthlyCampReports }}</h1>
    </header>
    <section class="container bg-block">
      <h3>{{ reportName }}</h3>
      <b-form-group class="form-element">
        <div class="form_label bold"><strong>{{ translations.components['report-parameters'].tcState }}</strong></div>
        <b-form-select
          class="form-control g-select w-full"
          :options="monthlyStates"
          v-model="monthlyState.org_key"
          :plain="true"
          @change="monthlyStateChange($event)"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div class="form_label bold"><strong>{{ translations.components['report-parameters'].tcCamp }}</strong></div>
        <b-form-select
          class="form-control g-select w-full"
          :options="monthlyCamps"
          v-model="monthlyCamp.org_key"
          :plain="true"
          @change="monthlyCampChange($event)"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div class="form_label"><strong>{{ translations.components['report-parameters'].tcYear }}</strong></div>
        <b-form-select
          class="form-control g-select w-full"
          :options="monthlyYears"
          :plain="true"
          v-model="monthlyYear"
          @change="monthlyYearChange($event)"
        ></b-form-select>
      </b-form-group>
      <b-form-group class="form-element">
        <div v-for="(ReportDetail, index) in this.monthlyUrls" :key="`g${index}`">
          <b-link target="_blank" :href="ReportDetail.value"
            ><strong>{{ ReportDetail.text }}</strong></b-link
          >
        </div>
      </b-form-group>
      <b-form-group>
        <b-button class="btn btn-tertiary btn-w-med ml-4" size="sm" id="btnCancelForm" @click="handleCancelClick"
          >{{ translations.components['report-parameters'].tcCancel }}</b-button
        >
      </b-form-group>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import { translationMixin } from '../../mixins/translationMixin'

export default {
  name: 'monthly-camp-reports',
  mixins: [translationMixin],
  data() {
    return {
      translations: {
        components: {
          'report-parameters': []
        }
      },
      title: 'Monthly Camp Reports',
      values: {},
      params: [],
      reportName: '',
      reportParamParentKey: '',
      monthlyStates: [
        {
          text: 'Select a State',
          value: constantData.empty_guid,
        },
      ],
      monthlyCamps: [],
      monthlyState: {
        lvl_code: null,
        org_key: null,
        org_name: null,
      },
      monthlyCamp: {
        lvl_code: null,
        org_key: null,
        org_name: null,
      },
      reportUrls: null,
      report: {
        key: null,
        value: null,
      },
      monthlyYear: null,
      monthlyYears: [],
      monthlyUrls: null,
      monthlyYearsData: {
        monthlyState: null,
        monthlyCamp: null,
      },
      monthlyUrlsData: {
        monthlyState: null,
        monthlyCamp: null,
        monthlyYear: null,
      },
    }
  },
  methods: {
    ...mapActions({
      getMonthlyCamps: 'secureReport/getMonthlyCamps',
      getMonthlyStates: 'secureReport/getMonthlyStates',
      getMonthlyUrls: 'secureReport/getMonthlyUrls',
      getMonthlyYears: 'secureReport/getMonthlyYears',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      this.setLoadingStatus(true)
      await this.getMonthlyStates()
      this.monthlyStates = this.monthlyStateDetails.map((item) => {
        return { text: item.org_name, value: item.org_key }
      })
      this.monthlyStates.unshift({ 
        text: this.translations.components['report-parameters'].tcSelectAState, 
        value: constantData.empty_guid
      })
      this.monthlyState.org_key = constantData.empty_guid
      this.monthlyState.key = ''
      this.monthlyCamp.org_key = constantData.empty_guid
      this.monthlyCamp.key = ''
      this.monthlyYear = ''
      this.setLoadingStatus(false)
    },
    async monthlyStateChange(evt) {
      this.setLoadingStatus(true)
      await this.getMonthlyCamps(this.monthlyState.org_key)
      this.monthlyCamps = this.monthlyCampDetails.map((item) => {
        return { text: item.org_name, value: item.org_key }
      })
      this.monthlyCamps.unshift({
        text: this.translations.components['report-parameters'].tcSelectACamp, 
        value: constantData.empty_guid
      })
      this.monthlyCamp.org_key = constantData.empty_guid
      this.monthlyCamp.key = ''
      this.monthlyYear = ''
      this.monthlyUrls = null
      this.setLoadingStatus(false)
    },
    async monthlyCampChange(evt) {
      this.setLoadingStatus(true)
      this.monthlyYearsData.monthlyState = this.monthlyStateDetails.find(
        (x) => x.org_key === this.monthlyState.org_key
      ).lvl_code
      this.monthlyYearsData.monthlyCamp = this.monthlyCampDetails.find(
        (x) => x.org_key === this.monthlyCamp.org_key
      ).org_key
      await this.getMonthlyYears(this.monthlyYearsData)
      this.monthlyYears = this.monthlyYearDetails.map((item) => {
        return { text: item, value: item }
      })
      this.monthlyYears.unshift({ 
        text: this.translations.components['report-parameters'].tcSelectAYear, 
        value: ''
      })
      this.monthlyYear = ''
      this.monthlyUrls = null
      this.setLoadingStatus(false)
    },
    async monthlyYearChange(evt) {
      this.setLoadingStatus(true)
      this.monthlyUrlsData.monthlyState = this.monthlyStateDetails.find(
        (x) => x.org_key === this.monthlyState.org_key
      ).lvl_code
      this.monthlyUrlsData.monthlyCamp = this.monthlyCampDetails.find(
        (x) => x.org_key === this.monthlyCamp.org_key
      ).org_key
      this.monthlyUrlsData.monthlyYear = this.monthlyYear

      await this.getMonthlyUrls(this.monthlyUrlsData)
      this.monthlyUrls = this.monthlyReportDetails.map((item) => {
        var objDate = new Date()
        objDate.setDate(1)
        objDate.setMonth(item.Month - 1)
        var month = objDate.toLocaleString(this.prefCulture, { month: 'long' })
        if (item.URL.includes('.csv')) {
          return { text: month + ' ' + item.Year + ' - ' + this.translations.components['report-parameters'].tcData, value: item.URL }
        }
        else {
          return { text: month + ' ' + item.Year, value: item.URL }
        }
      })
      this.setLoadingStatus(false)
    },
    setYearPlaceholder() {
      this.monthlyYears.push({ text: this.translations.components['report-parameters'].tcSelectAYear, value: '' })
    },
    setCampPlaceholder() {
      this.monthlyCamps.unshift({
        text: this.translations.components['report-parameters'].tcSelectACamp, 
        value: constantData.empty_guid
      })
    },
    handleCancelClick() {
      this.$router.push({ path: `/reports/report-center` })
    },
  },
  async created() {
    await this.getPseudoComponentTranslations('report-parameters').then(results => {
      const pseudoComponentTranslations = results
      this.$set(this.translations, 'components', pseudoComponentTranslations)
      this.setCampPlaceholder()
      this.setYearPlaceholder()
    })
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      monthlyCampDetails: 'secureReport/monthlyCamps',
      monthlyReportDetails: 'secureReport/monthlyReportUrls',
      monthlyStateDetails: 'secureReport/monthlyStates',
      monthlyYearDetails: 'secureReport/monthlyReportYears',
      userCamp: 'user/userCamp',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      prefCulture: 'user/userPreferredCulture',
      reportParentKey: 'reportParameter/reportParentKey',
      selectedReportKey: 'reportParameter/selectedReportKey',
    }),
  },
  components: {},
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

select {
  height: auto !important;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  max-width: 300px;
}

input[type='date'] {
  border: 1px solid #636363;
  padding: 13px 20px 13px 60px;
  height: auto;
  font-weight: bold;
  font-size: 16px;
}

input[type='checkbox'] {
  height: 30px;
  width: 30px;
}

.rpt-checkbox {
  font-weight: bolder;
  margin: 30px 15px 10px 0px;
}

.rpt-label {
  font-weight: bolder;
  margin-top: 30px;
}

.rpt-checkbox + div {
  display: inline-block;
}

div .report-download-btn {
  margin: 30pt 0px 10px 0px;
}
</style>
